import axios from 'axios';

const signup = async (data) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_SIGNUP}`,
      data: data,
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

const checkToken = async (token) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_CHECK_TOKEN}`,
      data: { token },
    });
    if (response instanceof Error) {
      return { error: response };
    }

    return response.data;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export { signup, checkToken };
