import axios from 'axios';

export const create = async (token, data) => {
  try {
    const response = await axios({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      url: process.env.REACT_APP_API_AGENTS,
      data: data,
    });

    if (response instanceof Error) {
      throw response;
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};
