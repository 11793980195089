import React from 'react';
import { withRouter } from 'react-router';
import Header from '../../commons/Header';
import { ReactComponent as Logo } from '../../images/EH_Logo.svg';
import './Step1.scss';
import {
  MainContainer,
  Stepper,
  StepperContainer,
  StepperItem,
  StepperItemContainer,
} from '../../commons/StyledComponents/StyledComponents';
import DocumentForm from '../../commons/DocumentForm';

function Step1Presentational({ agent, onSubmit, setAgent, handleUpload }) {
  return (
    <div className="App">
      <div>
        <section>
          <Header icon={<Logo />} />
        </section>

        <MainContainer>
          <StepperContainer>
            <hr />
            <Stepper>
              <li className="active">
                <StepperItemContainer number="1">
                  <StepperItem>1</StepperItem>
                </StepperItemContainer>
              </li>
              <li>
                <StepperItemContainer number="2">
                  <StepperItem>2</StepperItem>
                </StepperItemContainer>
              </li>
              <li>
                <StepperItemContainer number="3">
                  <StepperItem>3</StepperItem>
                </StepperItemContainer>
              </li>
            </Stepper>
          </StepperContainer>
          <DocumentForm
            agent={agent}
            setAgent={setAgent}
            submit={onSubmit}
            handleUpload={handleUpload}
          />
        </MainContainer>
      </div>
    </div>
  );
}

export default withRouter(Step1Presentational);
