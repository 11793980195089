import React, { useState } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import {
  FileText,
  FileUploadContainer,
} from '../StyledComponents/StyledComponents';
import { Button } from 'reactstrap';
import { ReactComponent as IconDownload } from '../../images/download.svg';

import './index.css';

function FileUploadButton({ fileName, id, labelName, onFileUpload, disabled }) {
  const [tmpFile, setTmpFile] = useState(null);

  const submitFile = () => {
    onFileUpload(tmpFile, id);
  };

  return (
    <div className="upload-container">
      <FileUploadContainer>
        <FileText>{fileName}</FileText>
        <FormGroup>
          <Label
            disabled={disabled}
            className={disabled ? 'custom-label-disabled' : 'custom-label'}
            for={id}
          >
            {' '}
            <IconDownload />
            {labelName}
          </Label>
          <Input
            disabled={disabled}
            type="file"
            name={id}
            onChange={(event) => {
              event.target.files
                ? setTmpFile(event.target.files[0])
                : setTmpFile(null);
            }}
            id={id}
            hidden
          />
        </FormGroup>
        <div className="button-container">
          <Button
            onClick={submitFile}
            hidden={!tmpFile || disabled}
            className="upload-btn"
            type="button"
          >
            Upload
          </Button>
        </div>
      </FileUploadContainer>
    </div>
  );
}

export default FileUploadButton;
