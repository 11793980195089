const createPassword = async (cognitoUser, password) => {
  try {
    const user = { Username: '' };
    return {
      data: {
        user,
        token: 'token',
      },
    };
  } catch (error) {
    return { error };
  }
};

export default createPassword;
