import React from 'react';
import { useAuth } from '../../commons/Auth';
import {
  HeadButtonCont,
  PrimaryButton,
  SecondaryButton,
} from '../StyledComponents/StyledComponents';
import { ReactComponent as PadLock } from '../../images/padlock.svg';
import logo from '../../images/new_logo.png';
import './Header.scss';
import { Link } from 'react-router-dom';

function Header({ icon }) {
  const [auth = {}, dispatch] = useAuth();

  const logout = () => dispatch({ type: 'logout' });

  return (
    <div className="header-crm">
      <div className="row">
        <div className="col-sm-12 col-md-4 col-lg-4">
          <img
            width="250"
            src={logo}
            alt=""
            className="u-center-center img-fluid"
          />
        </div>
        <div className="col-sm-12 col-md-8 col-lg-8">
          {auth.isAuthenticated ? (
            <HeadButtonCont>
              <SecondaryButton>800 887700</SecondaryButton>
              <PrimaryButton onClick={() => logout()}>Logout</PrimaryButton>
            </HeadButtonCont>
          ) : (
            <HeadButtonCont>
              <SecondaryButton>800 887700</SecondaryButton>
              <Link to={'/signup'}>
                <PrimaryButton>Crea Account</PrimaryButton>
              </Link>
              <Link to={'/'}>
                <PadLock />
                Accedi
              </Link>
            </HeadButtonCont>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
