import React, { useMemo } from 'react';

import Step1 from '../../pages/Step1';
import Step2 from '../../pages/Step2';
import Step3 from '../../pages/Step3';
import Login from '../../pages/Login';
import { useAuth } from './AuthProvider';

const SignupStepper = () => {
  const [{ isAuthenticated, profile }] = useAuth();
  const Component = useMemo(() => {
    const fileCheck =
      profile.identityCard &&
      profile.cameral &&
      profile.ivass &&
      profile.rcInsurance &&
      profile.fiscalCode;
    if (isAuthenticated) {
      if (profile.status && fileCheck) {
        //step3
        return <Step3 />;
      } else {
        if (fileCheck) {
          //step2
          return <Step2 />;
        } else {
          //step1
          return <Step1 />;
        }
      }
    } else {
      //step1
      return <Login />;
    }
  }, [isAuthenticated, profile]);

  return Component;
};

export default SignupStepper;
