import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../commons/Auth';
import Step3Presentational from './Step3Presentational';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../commons/Loading';
const FileDownload = require('js-file-download');

function Step3(props) {
  const [t] = useTranslation();
  const [auth = {}, dispatch] = useAuth();
  const { token, profile } = auth;
  const { showLoading, hideLoading } = useLoading();

  const initialState = {
    id: null,
    document: null,
  };

  const [agent, setAgent] = useState(initialState);
  const alert = useAlert();
  const [isLoading] = useState(true);

  useEffect(() => {
    const changeProfile = () => {
      setAgent({
        id: profile.id,
        document: profile.document,
        unsignedDocument: profile.unsignedDocument,
      });
    };
    changeProfile();
  }, [profile]);

  const handleUpload = async (file, type) => {
    try {
      showLoading();
      var formData = new FormData();
      formData.append(type, file);
      formData.append('id', agent.id);

      const response = await axios({
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
        url: process.env.REACT_APP_API_DOCUMENTS + '/upload',
      });

      if (response instanceof Error) {
        throw response;
      }

      alert.success(t('CONTRATTO CARICATO!'));
      dispatch({ type: 'syncProfile' });
      hideLoading();
    } catch (error) {
      alert.error(t('ERRORE NEL CARICAMENTO!'));
      hideLoading();
    }
  };

  const downloadDocument = async () => {
    try {
      showLoading();
      const { status, data } = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_DOCUMENTS}/download/${agent.unsignedDocument}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (status && status === 200) {
        FileDownload(data.file, data.metadata.fileName);
        alert.success('CONTRATTO SCARICATO');
      } else {
        alert.error('ERRORE DURANTE DOWNLOAD');
      }
      hideLoading();
    } catch (error) {
      alert.error('ERRORE DURANTE DOWNLOAD');
      hideLoading();
    }
  };

  return (
    <Step3Presentational
      isLoading={isLoading}
      agent={agent}
      handleUpload={handleUpload}
      downloadDocument={downloadDocument}
      {...props}
    />
  );
}

export default Step3;
