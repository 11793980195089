import React, { useState, useRef } from 'react';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';
import { checkToken } from '../../lib/fetch/signup';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  Card,
  Button,
  CardHeader,
  CardFooter,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  CustomInput,
} from 'reactstrap';
import { FormContainer } from '../../commons/StyledComponents/StyledComponents';
import ReactTooltip from 'react-tooltip';
import useForm from 'react-hook-form';
import LogoHeader from '../../commons/LogoHeader';
import { ReactComponent as IconInfo } from '../../images/info.svg';
import { validaCodiceFiscale, validatePIVA } from '../../lib/helpers/validator';
import './Signup.scss';

const TYPES = {
  SINGLE: 'single',
  COMPANY: 'company',
};

function SignupPresentational({ match, onSignup }) {
  const [disabled, setDisabled] = useState(true);
  const [type, setType] = useState(TYPES.SINGLE);
  const recaptchaRef = useRef();

  const { handleSubmit, register, errors } = useForm();

  const checkCaptcha = async (token) => {
    const { error } = await checkToken(token);
    if (error) {
      if (recaptchaRef.current != null) recaptchaRef.current.reset();
    } else {
      setDisabled(false);
    }
  };

  return (
    <div className="App">
      <LogoHeader match={match} />

      <section>
        <div className="container">
          <Card className="u-center-horizontal login-card">
            <CardHeader>
              <CardTitle>
                <span>Non hai ancora un account?</span>
                <br />
                <span>Registrati ora</span>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <FormContainer>
                <Form onSubmit={handleSubmit(onSignup)}>
                  <div className="radio-buttons">
                    <FormGroup tag="fieldset">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="type"
                            value={TYPES.SINGLE}
                            defaultChecked={true}
                            onClick={() => setType(TYPES.SINGLE)}
                            innerRef={register({
                              required: 'Campo obbligatorio',
                            })}
                          />{' '}
                          Ditta Individuale
                        </Label>
                      </FormGroup>
                      <span data-tip="Seleziona Ditta Individuale se svolgi la tua attività <br/>come persona fisica con partita IVA.">
                        <IconInfo />
                      </span>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="radio"
                            name="type"
                            value={TYPES.COMPANY}
                            onClick={() => setType(TYPES.COMPANY)}
                            innerRef={register({
                              required: 'Campo obbligatorio',
                            })}
                          />{' '}
                          Società
                        </Label>
                      </FormGroup>
                      <span data-tip="Seleziona Società se la tua realtà professionale <br/>è organizzata come società (es. s.r.l., s.n.c., s.a.s.)">
                        <IconInfo />
                      </span>
                      <ReactTooltip multiline />
                      {errors.type && errors.type.message && (
                        <FormText className="error-message">
                          {errors.type.message}
                        </FormText>
                      )}
                    </FormGroup>
                  </div>
                  <FormGroup>
                    <Label>
                      {type === TYPES.SINGLE ? 'Codice fiscale' : 'Partita iva'}
                    </Label>
                    <Input
                      type="text"
                      name="agentFiscalCode"
                      innerRef={register({
                        required: 'Campo obbligatorio',
                        validate:
                          type === TYPES.SINGLE
                            ? validaCodiceFiscale
                            : validatePIVA,
                      })}
                    />
                    {errors.agentFiscalCode?.message && (
                      <FormText className="error-message">
                        {errors.agentFiscalCode.message}
                      </FormText>
                    )}
                    {errors.agentFiscalCode?.type === 'validate' && (
                      <FormText className="error-message">
                        {type === TYPES.SINGLE
                          ? 'Codice fiscale non valido'
                          : 'Partita iva non valida'}
                      </FormText>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label>
                      {type === TYPES.SINGLE
                        ? 'Codice RUI Titolare'
                        : 'Codice RUI Società'}
                    </Label>
                    <Input
                      type="text"
                      name="companyRui"
                      innerRef={register({
                        required: 'Campo obbligatorio',
                      })}
                    />
                    {errors.companyRui && errors.companyRui.message && (
                      <FormText className="error-message">
                        {errors.companyRui.message}
                      </FormText>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label>Ragione sociale</Label>
                    <Input
                      type="text"
                      name="companyName"
                      innerRef={register({
                        required: 'Campo obbligatorio',
                      })}
                    />
                    {errors.companyName && errors.companyName.message && (
                      <FormText className="error-message">
                        {errors.companyName.message}
                      </FormText>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label>Sede legale</Label>
                    <Input
                      type="text"
                      name="companyCity"
                      innerRef={register({
                        required: 'Campo obbligatorio',
                      })}
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label>Email</Label>
                    <Input
                      type="email"
                      name="email"
                      innerRef={register({
                        required: 'Campo obbligatorio',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'Indirizzo email non valido',
                        },
                      })}
                    />
                    {errors.email && errors.email.message && (
                      <FormText className="error-message">
                        {errors.email.message}
                      </FormText>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <div>
                      <CustomInput
                        type="checkbox"
                        id="confirmTerms"
                        name="confirmTerms"
                        label="Accetta i termini e le condizioni di utilizzo"
                        innerRef={register({
                          required: 'Campo obbligatorio',
                        })}
                      />
                    </div>
                    {errors.confirmTerms && errors.confirmTerms.message && (
                      <FormText className="error-message">
                        {errors.confirmTerms.message}
                      </FormText>
                    )}
                  </FormGroup>
                  <FormGroup id="captchaContainer" name="captchaContainer">
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6LeFmRwcAAAAAFHYX9ydtRjDp0Wdh2GdXDWRjEUx"
                      onChange={checkCaptcha}
                    />
                  </FormGroup>
                  <Button
                    disabled={disabled}
                    type="submit"
                    className="u-center-horizontal custom-button"
                  >
                    Registrati
                  </Button>
                </Form>
              </FormContainer>
            </CardBody>
            <CardFooter>
              <div className="row">
                <div className="col-6">Hai già un account?</div>
                <div className="col-6 text-right">
                  <Link to={'/'}>
                    Login <i className="fas fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </CardFooter>
          </Card>
        </div>
      </section>
    </div>
  );
}

SignupPresentational.propTypes = {
  onSignup: Proptypes.func.isRequired,
};

export default SignupPresentational;
