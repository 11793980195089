import React, { useState, useEffect } from 'react';
import { create } from '../../lib/fetch/agents';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import Step1Presentational from './Step1Presentational';
import { useAlert } from 'react-alert';

function Step1() {
  const [auth = {}] = useAuth();
  const { token, profile } = auth;
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const initialState = {
    id: null,
    email: '',
    agentFiscalCode: '',
    agentRui: '',
    iban: '',
    companyRui: '',
    type: 'single',
    confirm: false,
    confirmTerms: false,
    confirmPrivacy: false,
    identityCard: null,
    cameral: null,
    fiscalCode: null,
    ivass: null,
    rcInsurance: null,
  };

  const [agent, setAgent] = useState(initialState);
  const onSubmit = async () => {
    if (
      !(
        agent.identityCard &&
        agent.cameral &&
        agent.ivass &&
        agent.rcInsurance &&
        agent.fiscalCode
      )
    ) {
      alert.error('CARICARE TUTTI I FILE');
      return;
    }
    showLoading();
    const { error } = await create(token, agent);
    hideLoading();
    if (error) {
      alert.error('ERRORE DURANTE IL CARICAMENTO DEI FILE, RIPROVARE');
      return;
    }
    alert.success('STEP 1 COMPLETATO');
    window.location.reload();
  };

  useEffect(() => {
    const changeProfile = () => {
      setAgent({ ...initialState, ...profile });
    };
    changeProfile();
    // eslint-disable-next-line
  }, [profile]);

  return (
    <Step1Presentational
      onSubmit={onSubmit}
      agent={agent}
      setAgent={setAgent}
    />
  );
}

export default Step1;
