import React from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import createPassword from '../../lib/fetch/createPassword';
import { formatError } from './formatError';
import { useAuth } from '../../commons/Auth';
import CreatePasswordPresentational from './CreatePasswordPresentational';

function CreatePassword(props) {
  const { t } = useTranslation('LOGIN');
  const alert = useAlert();
  const [auth, dispatch] = useAuth();

  const onCreatePassword = async ({ password = '' }) => {
    const { error, data = {} } = await createPassword(auth.user, password);

    if (error) {
      alert.error(formatError(error, t));
      return;
    }

    const { token } = data;
    dispatch({ type: 'login', token });
  };

  // eslint-disable-next-line no-restricted-globals

  return (
    <CreatePasswordPresentational
      onCreatePassword={onCreatePassword}
      {...props}
    />
  );
}

export default CreatePassword;
