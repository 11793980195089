// import fetchMe from './me';
import fakeLogin from './fakeLogin';

const login = async (email, password) => {
  try {
    // const response = await fetchMe(email);
    const response = await fakeLogin();
    if (response instanceof Error) {
      return { error: response };
    }
    if (!response.data) {
      return {
        data: {
          user: null,
        },
      };
    } else {
      return {
        data: {
          token: response.data,
          user: response.data,
        },
      };
    }
  } catch (error) {
    return { error };
  }
};

export default login;
