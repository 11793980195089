import React from 'react';
import Proptypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardTitle, CardFooter } from 'reactstrap';
import './Login.scss';
import Header from '../../commons/Header';
import { LoginContainer } from '../../commons/StyledComponents/StyledComponents';
// import { useAuth } from '../../commons/Auth';
// import axios from 'axios';

// import OAuth2Login from 'react-simple-oauth2-login';

function LoginPresentational({ match, onLogin }) {
  //   const [, dispatch] = useAuth();

  //   const onSuccess = ({ code, ...rest }) => {
  //     console.log(code, rest);
  //     axios({
  //       method: 'POST',
  //       url: `${process.env.REACT_APP_BASE_URL}/authorize/token`,
  //       data: { code, type: 'external' },
  //     })
  //       .then((data) => {
  //         console.log(data);
  //         dispatch({ type: 'login', token: data.data.access_token });
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };
  //   const onFailure = (response) => {
  //     onLogin({ email: '', password: '' });
  //   };

  return (
    <div className="App">
      <section>
        <Header />
      </section>

      <LoginContainer>
        <div className="container">
          <Card className="u-center-horizontal login-card">
            <CardHeader>
              <CardTitle>
                <span>Effettua il login</span>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <a
                type="button"
                className="u-center-horizontal custom-button"
                href={
                  process.env.REACT_APP_AUTHORIZATION_URL +
                  '?client_id=' +
                  process.env.REACT_APP_CLIENT_ID +
                  '&redirect_uri=' +
                  process.env.REACT_APP_REDIRECT_URI +
                  '&response_type=code&scope=openid'
                }
              >
                Login with EH
              </a>
              {/* <OAuth2Login
                isCrossOrigin={true}
                className="u-center-horizontal custom-button"
                buttonText="Login with EH"
                authorizationUrl={process.env.REACT_APP_AUTHORIZATION_URL}
                responseType="code"
                clientId={process.env.REACT_APP_CLIENT_ID}
                scope="openid"
                redirectUri={process.env.REACT_APP_REDIRECT_URI}
                onSuccess={onSuccess}
                onFailure={onFailure}
              /> */}
            </CardBody>
            <CardFooter>
              <div className="row">
                <div className="col-6">Non hai ancora un account?</div>
                <div className="col-6 text-right">
                  <Link to={'/signup'}>
                    Registrati <i className="fas fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </CardFooter>
          </Card>
        </div>
      </LoginContainer>
    </div>
  );
}

LoginPresentational.propTypes = {
  onLogin: Proptypes.func.isRequired,
};

export default LoginPresentational;
