import axios from 'axios';

const fetchMe = async (token) => {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_API_ME,
      headers: {
        Authorization: token,
      },
    });
    if (response instanceof Error) {
      throw response;
    }
    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export default fetchMe;
