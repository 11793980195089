export default {
  Allianz: 'Allianz, sans-serif',

  background: '#eeeeee',
  black: '#212529',
  white: '#ffffff',
  grey: '#495057',
  pink: '#e0c7c2',
  primary: '#2768a7',
  success: '#8fc485',
  danger: '#d87373',
  warning: '#ed9b00',
};
