import React from 'react';
import { withRouter } from 'react-router';
import Header from '../../commons/Header';
import { ReactComponent as Logo } from '../../images/EH_Logo.svg';
import './Step3.scss';
import {
  ContractFrameContainer,
  MainContainer,
  Stepper,
  StepperContainer,
  StepperItem,
  StepperItemContainer,
  DocumentUploadContainer,
  MarginTB30,
} from '../../commons/StyledComponents/StyledComponents';
import { Button } from 'reactstrap';
import FileUploadButton from '../../commons/FileUploadButton';

function Step3Presentational({ agent, handleUpload, downloadDocument }) {
  const { document, unsignedDocument } = agent;
  return (
    <div className="App">
      <div>
        <section>
          <Header icon={<Logo />} />
        </section>

        <MainContainer>
          <StepperContainer>
            <hr />
            <Stepper>
              <li className="active">
                <StepperItemContainer number="1">
                  <StepperItem>1</StepperItem>
                </StepperItemContainer>
              </li>
              <li className="active">
                <StepperItemContainer number="2">
                  <StepperItem>2</StepperItem>
                </StepperItemContainer>
              </li>
              <li>
                <StepperItemContainer number="3">
                  <StepperItem>3</StepperItem>
                </StepperItemContainer>
              </li>
            </Stepper>
          </StepperContainer>
          <DocumentUploadContainer>
            <MarginTB30>
              <p>
                Scarica il tuo accordo di collaborazione con Euler Hermes,
                verifica i dati inseriti, firmalo digitalmente fai upload e
                comincia a collaborare con Euler Hermes.
              </p>
            </MarginTB30>
            <ContractFrameContainer>
              <Button
                className="custom-button"
                disabled={!unsignedDocument}
                onClick={downloadDocument}
              >
                Scarica
              </Button>
            </ContractFrameContainer>

            <FileUploadButton
              id={'document'}
              disabled={document ? true : false}
              onFileUpload={handleUpload}
              fileName={document ? 'Caricato' : 'Non caricato'}
              labelFor="contract"
              labelName="Carica accordo collaborazione"
            />
          </DocumentUploadContainer>
        </MainContainer>
      </div>
    </div>
  );
}

export default withRouter(Step3Presentational);
