import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import login from '../../lib/fetch/login';
import { formatError } from './formatError';
import { useAuth } from '../../commons/Auth';
import LoginPresentational from './LoginPresentational';

function Login(props) {
  const { t } = useTranslation('LOGIN');
  const alert = useAlert();
  const [, dispatch] = useAuth();
  const history = useHistory();

  const onLogin = async ({ email = '', password = '' }) => {
    const { error, data = {} } = await login(email, password);
    if (error) {
      alert.error(formatError(error, t));
      return;
    }
    const { token, user } = data;
    if (token) {
      dispatch({ type: 'login', token });
      history.push('/registration');
      return;
    }
    if (user)
      switch (user.challengeName) {
        case 'NEW_PASSWORD_REQUIRED':
          dispatch({ type: 'createPassword', user });
          history.push('/create-password');
          break;
        default:
          break;
      }
  };

  // eslint-disable-next-line no-restricted-globals
  return <LoginPresentational onLogin={onLogin} {...props} />;
}

export default Login;
