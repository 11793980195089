import React, { useState } from 'react';
import Step2Presentational from './Step2Presentational';

function Step2(props) {
  const [isLoading] = useState(true);
  const [data] = useState({});

  return <Step2Presentational isLoading={isLoading} data={data} {...props} />;
}

export default Step2;
