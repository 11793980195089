import React from 'react';
import { signup } from '../../lib/fetch/signup';
import SignupPresentational from './SignupPresentational';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router';

function Signup(props) {
  const alert = useAlert();
  const history = useHistory();
  const { showLoading, hideLoading } = useLoading();

  const onSignup = async (agent) => {
    const data = { ...agent };
    showLoading();
    const { error } = await signup(data);
    hideLoading();
    if (error) {
      alert.error("ERRORE DURANTE LA CREAZIONE DELL'UTENTE");
      return;
    }
    alert.success('UTENTE CREATO');
    history.push('/');
    //TODO
  };

  return <SignupPresentational onSignup={onSignup} {...props} />;
}

export default Signup;
