import axios from 'axios';

const fakeLogin = async () => {
  try {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_BASE_URL + '/tmplogin',
    });
    if (response instanceof Error) {
      throw response;
    }
    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export default fakeLogin;
