import React, { useRef } from 'react';
import Proptypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
} from 'reactstrap';
import useForm from 'react-hook-form';
import './CreatePassword.scss';
import Header from '../../commons/Header';
import { ReactComponent as Logo } from '../../images/EH_Logo.svg';
import {
  LoginContainer,
  PrimaryButton,
} from '../../commons/StyledComponents/StyledComponents';

function CreatePasswordPresentational({ match, onCreatePassword }) {
  const { handleSubmit, register, errors, watch } = useForm();
  const password = useRef({});
  password.current = watch('password', '');

  return (
    <div className="App">
      <section>
        <Header icon={<Logo />} />
      </section>

      <LoginContainer>
        <div className="container">
          <Card className="u-center-horizontal login-card">
            <CardHeader>
              <CardTitle>
                <span>Crea password permanente</span>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit(onCreatePassword)}>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    type="password"
                    name="password"
                    innerRef={register({
                      required: 'campo obbligatorio',
                      pattern: {
                        minLength: 6,
                        maxLength: 20,
                        message: 'password non corretta',
                      },
                    })}
                  />
                  {errors.password && errors.password.message && (
                    <FormText className="error-message">
                      {errors.password.message}
                    </FormText>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label>Conferma Password</Label>
                  <Input
                    type="password"
                    name="confirm"
                    innerRef={register({
                      validate: (value) =>
                        value === password.current
                          ? true
                          : 'le password non corrispondono',
                      required: 'campo obbligatorio',
                    })}
                  />
                  {errors.confirm && errors.confirm.message && (
                    <FormText className="error-message">
                      {errors.confirm.message}
                    </FormText>
                  )}
                </FormGroup>

                <PrimaryButton type="submit" className="u-center-horizontal">
                  Conferma
                </PrimaryButton>
              </Form>
            </CardBody>
          </Card>
        </div>
      </LoginContainer>
    </div>
  );
}

CreatePasswordPresentational.propTypes = {
  onCreatePassword: Proptypes.func.isRequired,
};

export default CreatePasswordPresentational;
