import React from 'react';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {
  FormContainer,
  PrimaryButton,
} from '../StyledComponents/StyledComponents';
import { ReactComponent as IconInfo } from '../../images/info.svg';
import ReactTooltip from 'react-tooltip';
import useForm from 'react-hook-form';

import axios from 'axios';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';

import {
  DocumentUploadContainer,
  MarginTB30,
} from '../StyledComponents/StyledComponents';
import FileUploadButton from '../FileUploadButton';

const TYPES = {
  SINGLE: 'single',
  COMPANY: 'company',
};

const DOC_PATHS = {
  IDCARD: 'identityCard',
  CAMERAL: 'cameral',
  FISCALCODE: 'fiscalCode',
  IVASS: 'ivass',
  RCINSURANCE: 'rcInsurance',
};

function DocumentForm({ agent, setAgent, submit }) {
  const {
    email,
    agentFiscalCode,
    companyRui,
    identityCard,
    iban,
    cameral,
    fiscalCode,
    ivass,
    rcInsurance,
  } = agent;

  const [auth = {}] = useAuth();
  const alert = useAlert();
  const { token } = auth;
  const { showLoading, hideLoading } = useLoading();

  const onFileUpload = async (file, type) => {
    try {
      showLoading();
      var formData = new FormData();
      formData.append(type, file);
      formData.append('id', agent.id);

      const response = await axios({
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
        url: process.env.REACT_APP_API_DOCUMENTS + '/upload',
      });

      if (response instanceof Error) {
        throw response;
      }

      alert.success('CONTRATTO CARICATO!');
      agent[type] = true;
      hideLoading();
    } catch (error) {
      alert.error('ERRORE NEL CARICAMENTO!');
      hideLoading();
    }
  };

  const { handleSubmit, register, errors } = useForm();

  return (
    <>
      <FormContainer>
        <Form onSubmit={handleSubmit(submit)}>
          <div className="radio-buttons">
            <FormGroup tag="fieldset">
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                    value={TYPES.SINGLE}
                    checked={agent.type === TYPES.SINGLE}
                    onChange={() => setAgent({ ...agent, type: TYPES.SINGLE })}
                    innerRef={register({
                      required: 'Campo obbligatorio',
                    })}
                  />{' '}
                  Ditta Individuale
                </Label>
              </FormGroup>
              <span data-tip="Seleziona Ditta Individuale se svolgi la tua attività <br/>come persona fisica con partita IVA.">
                <IconInfo />
              </span>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                    value={TYPES.COMPANY}
                    checked={agent.type === TYPES.COMPANY}
                    onChange={() => setAgent({ ...agent, type: TYPES.COMPANY })}
                    innerRef={register({
                      required: 'Campo obbligatorio',
                    })}
                  />{' '}
                  Società
                </Label>
              </FormGroup>
              <span data-tip="Seleziona Società se la tua realtà professionale <br/>è organizzata come società (es. s.r.l., s.n.c., s.a.s.)">
                <IconInfo />
              </span>
              <ReactTooltip multiline />
              {errors.radio1 && errors.radio1.message && (
                <FormText className="error-message">
                  {errors.radio1.message}
                </FormText>
              )}
            </FormGroup>
          </div>

          <FormGroup>
            <Input
              type="text"
              name="RUI"
              value={companyRui}
              placeholder={
                agent.type === TYPES.SINGLE
                  ? 'Codice RUI Titolare'
                  : 'Codice RUI Società'
              }
              onChange={(e) =>
                setAgent({ ...agent, companyRui: e.target.value })
              }
              innerRef={register({
                required: 'Campo obbligatorio',
              })}
            />
            {errors.RUI && errors.RUI.message && (
              <FormText className="error-message">
                {errors.RUI.message}
              </FormText>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              type="text"
              name="PIVA"
              value={agentFiscalCode}
              placeholder={
                agent.type === TYPES.SINGLE
                  ? 'Codice fiscale'
                  : 'Partita iva Società'
              }
              onChange={(e) =>
                setAgent({ ...agent, agentFiscalCode: e.target.value })
              }
              innerRef={register({
                required: 'Campo obbligatorio',
              })}
            />
            {errors.PIVA && errors.PIVA.message && (
              <FormText className="error-message">
                {errors.PIVA.message}
              </FormText>
            )}
          </FormGroup>
          <FormGroup>
            <Input
              type="email"
              name="email"
              disabled
              value={email}
              placeholder="Email dell'attività (NO PEC)"
              onChange={(e) => setAgent({ ...agent, email: e.target.value })}
              innerRef={register({
                required: 'Campo obbligatorio',
              })}
            />
            {errors.email && errors.email.message && (
              <FormText className="error-message">
                {errors.email.message}
              </FormText>
            )}
          </FormGroup>

          <FormGroup>
            <Input
              type="iban"
              name="iban"
              value={iban}
              placeholder="IBAN"
              onChange={(e) => setAgent({ ...agent, iban: e.target.value })}
              innerRef={register({
                required: 'Campo obbligatorio',
              })}
            />
            {errors.iban && errors.iban.message && (
              <FormText className="error-message">
                {errors.email.message}
              </FormText>
            )}
          </FormGroup>

          <DocumentUploadContainer>
            <MarginTB30>
              <p>
                Per poter collaborare con Euler Hermes assicurati di avere a
                disposizione un dispositivo per la firma digitale ed allega qui
                i seguenti documenti. Una volta caricati riceverai una mail di
                conferma e la bozza del nostro accordo di collaborazione
              </p>
              <FileUploadButton
                fileName={identityCard ? 'Caricato' : 'File non caricato'}
                id={DOC_PATHS.IDCARD}
                value={identityCard}
                disabled={identityCard}
                labelName="Carta d'identità"
                onFileUpload={onFileUpload}
                innerCheck={register}
                errors={errors}
              />
              <FileUploadButton
                fileName={cameral ? 'Caricato' : 'File non caricato'}
                id={DOC_PATHS.CAMERAL}
                value={cameral}
                disabled={cameral}
                labelName="Visura Camerale"
                onFileUpload={onFileUpload}
                innerCheck={register}
                errors={errors}
              />
              <FileUploadButton
                fileName={fiscalCode ? 'Caricato' : 'File non caricato'}
                id={DOC_PATHS.FISCALCODE}
                value={fiscalCode}
                labelName="Codice Fiscale"
                onFileUpload={onFileUpload}
                disabled={fiscalCode}
                innerCheck={register}
                errors={errors}
              />
              <FileUploadButton
                fileName={ivass ? 'Caricato' : 'File non caricato'}
                id={DOC_PATHS.IVASS}
                value={ivass}
                labelName="Iscrizione Ivass"
                disabled={ivass}
                onFileUpload={onFileUpload}
                innerCheck={register}
                errors={errors}
              />
              <FileUploadButton
                fileName={rcInsurance ? 'Caricato' : 'File non caricato'}
                id={DOC_PATHS.RCINSURANCE}
                value={rcInsurance}
                disabled={rcInsurance}
                labelName="Polizza RC Professionale"
                onFileUpload={onFileUpload}
                innerCheck={register}
                errors={errors}
              />

              <p>
                Ai fini della sottoscrizione dell’Accordo di collaborazione si
                dichiara:
                <br />
                - il possesso e l’utilizzo della firma digitale di cui all’art.
                1, comma 1, lett. s), del D. Lgs. n. 82/2005, in corso di
                validità;
                <br />- che il documento informatico “Accordo di collaborazione”
                sarà sottoscritto con firma digitale autenticata dal soggetto in
                possesso dei poteri necessari.
              </p>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="confirm"
                    onChange={(e) =>
                      setAgent({ ...agent, confirm: e.target.checked })
                    }
                    innerRef={register({
                      required: 'Campo obbligatorio',
                    })}
                  />{' '}
                  Confermo
                </Label>
                {errors.confirm && errors.confirm.message && (
                  <FormText className="error-message">
                    {errors.confirm.message}
                  </FormText>
                )}
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="confirmTerms"
                    onChange={(e) =>
                      setAgent({ ...agent, confirmTerms: e.target.checked })
                    }
                    innerRef={register({
                      required: 'Campo obbligatorio',
                    })}
                  />{' '}
                  Confermo di aver letto i{' '}
                  <a
                    href="./docs/terms-and-conditions-template.pdf"
                    target="_blank"
                  >
                    termini e condizioni
                  </a>
                </Label>
                {errors.confirmTerms && errors.confirmTerms.message && (
                  <FormText className="error-message">
                    {errors.confirmTerms.message}
                  </FormText>
                )}
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    name="confirmPrivacy"
                    onChange={(e) =>
                      setAgent({ ...agent, confirmPrivacy: e.target.checked })
                    }
                    innerRef={register({
                      required: 'Campo obbligatorio',
                    })}
                  />{' '}
                  Confermo di aver letto la{' '}
                  <a href="./docs/privacy-policy-template.pdf" target="_blank">
                    privacy policy
                  </a>
                </Label>
                {errors.confirmPrivacy && errors.confirmPrivacy.message && (
                  <FormText className="error-message">
                    {errors.confirmPrivacy.message}
                  </FormText>
                )}
              </FormGroup>
            </MarginTB30>
          </DocumentUploadContainer>
          <PrimaryButton type="submit">Avanti</PrimaryButton>
        </Form>
      </FormContainer>
    </>
  );
}

export default DocumentForm;
