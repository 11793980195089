import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';
import { QueryParamProvider } from 'use-query-params';
import { Provider as AlertProvider, positions } from 'react-alert';
import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/it';
import {
  AuthProvider,
  PrivateRoute,
  PublicRoute,
  RoutesWrapper,
  SignupStepper,
} from './commons/Auth';
import AlertTemplate from './commons/AlertTemplate';
import { LoadingProvider } from './commons/Loading';
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Reset from './pages/Reset';
import Authentication from './pages/Authentication';
import CreatePassword from './pages/CreatePassword';
import Verification from './pages/Verification';
import Signup from './pages/Signup';
import PageNotFound from './pages/PageNotFound';
import translations from './translations';
import './App.scss';

i18next.init({
  resources: translations,
  lng: 'it',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

moment.locale('it');

function App() {
  return (
    <CookiesProvider>
      <div className="App">
        <I18nextProvider i18n={i18next}>
          <AlertProvider
            template={AlertTemplate}
            position={positions.TOP_RIGHT}
            timeout={5000}
          >
            <AuthProvider>
              <LoadingProvider>
                <Router>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <RoutesWrapper
                      publicRedirect="/"
                      privateRedirect="/registration"
                    >
                      <PublicRoute exact path="/" component={Login} />
                      <PublicRoute path="/signup" component={Signup} />
                      <PublicRoute path="/forgot" component={Forgot} />
                      <PublicRoute path="/reset" component={Reset} />
                      {/* <PublicRoute path="/authentication" component={Authentication} /> */}
                      <PublicRoute
                        path="/authentication"
                        component={Authentication}
                      />
                      <PublicRoute
                        path="/create-password"
                        component={CreatePassword}
                      />
                      <PublicRoute
                        path="/verification"
                        component={Verification}
                      />
                      <PrivateRoute
                        path="/registration/:id?"
                        component={SignupStepper}
                      />
                      <Route component={PageNotFound} />
                    </RoutesWrapper>
                  </QueryParamProvider>
                </Router>
              </LoadingProvider>
            </AuthProvider>
          </AlertProvider>
        </I18nextProvider>
      </div>
    </CookiesProvider>
  );
}

export default App;
