import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../commons/Auth';
import { useAlert } from 'react-alert';

// import qs from 'qs';

function Authentication(props) {
  const [{ isAuthenticated }, dispatch] = useAuth();

  const alert = useAlert();

  //   const [error, setError] = useState(false);

  useEffect(() => {
    async function callApi() {
      try {
        var res = await axios({
          method: 'POST',
          url: `${process.env.REACT_APP_BASE_URL}/authorize/token`,
          data: {
            code: new URLSearchParams(props.location.search).get('code'),
            type: 'external',
          },
        });

        alert.success('LOGIN EFFETTUATO');
        dispatch({ type: 'login', token: res.data.access_token });
      } catch (err) {
        alert.error('ERRORE DURANTE LOGIN');
        // setError(true);
      }
    }
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      {isAuthenticated ? (
        <div>
          <Redirect
            push={false}
            to={{
              pathname: '/',
            }}
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default Authentication;
