import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';

function PublicRoute(props) {
  const {
    component: Component,
    isAuthenticated = false,
    privateRedirect = '/registration',
    ...rest
  } = props;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect
            push={false}
            to={{
              pathname: privateRedirect,
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

PublicRoute.propTypes = {
  exact: Proptypes.bool,
  path: Proptypes.string,
  component: Proptypes.func.isRequired,
  privateDefaultPath: Proptypes.string,
  isAuthenticated: Proptypes.bool,
};

export default PublicRoute;
