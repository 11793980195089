import React from 'react';
import { withRouter } from 'react-router';
import Header from '../../commons/Header';
import { ReactComponent as Logo } from '../../images/EH_Logo.svg';
import './Step2.scss';
import {
  MainContainer,
  MessageContainer,
  Stepper,
  StepperContainer,
  StepperItem,
  StepperItemContainer,
} from '../../commons/StyledComponents/StyledComponents';

function Step2Presentational() {
  return (
    <div className="App">
      <div>
        <section>
          <Header icon={<Logo />} />
        </section>

        <MainContainer>
          <StepperContainer>
            <hr />
            <Stepper>
              <li>
                <StepperItemContainer number="1">
                  <StepperItem>1</StepperItem>
                </StepperItemContainer>
              </li>
              <li className="active">
                <StepperItemContainer number="2">
                  <StepperItem>2</StepperItem>
                </StepperItemContainer>
              </li>
              <li>
                <StepperItemContainer number="3">
                  <StepperItem>3</StepperItem>
                </StepperItemContainer>
              </li>
            </Stepper>
          </StepperContainer>
        </MainContainer>
        <MessageContainer>
          <p>
            A breve riceverai una mail di conferma e la bozza del nostro accordo
            di collaborazione.
          </p>
        </MessageContainer>
      </div>
    </div>
  );
}

export default withRouter(Step2Presentational);
