import styled from 'styled-components';
import variables from './Variables';

export const MarginTB30 = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 40px auto;
  @media only screen and (max-width: 480px) {
    max-width: 340px;
  }
`;

export const MessageContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  text-align: center;
  @media only screen and (max-width: 480px) {
    max-width: 340px;
  }
`;

export const PrimaryButton = styled.button`
  background-color: ${variables.primary};
  color: ${variables.white};
  border: 1px solid ${variables.primary};
  padding: 6px 24px;
  border-radius: 4px;
  min-width: 160px;
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: ${variables.white};
  color: ${variables.primary};
`;

export const HeadButtonCont = styled.div`
  width: max-content;
  float: right;
  padding-top: 3px;
  button {
    margin: 0 12px;
  }
  a {
    color: ${variables.primary};
    svg {
      margin-right: 10px;
      margin-bottom: 4px;
    }
  }

  @media only screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    float: none;
    width: 100%;
    align-items: center;
    button {
      margin: 12px 0;
    }
  }
`;
export const StepperItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid ${variables.grey};
  background-color: ${variables.background};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`;

export const Stepper = styled.ul`
  list-style-type: none;
  display: flex;
  padding: 0;
  width: 100%;
  justify-content: space-between;
  li {
    color: ${variables.grey};
  }
  .active {
    color: ${variables.primary};
    ${StepperItem} {
      border: 2px solid ${variables.primary};
    }
  }
`;

export const StepperContainer = styled.div`
  position: relative;
  width: 100%;
  hr {
    border-top: 1px solid ${variables.primary};
    position: absolute;
    width: 90%;
    z-index: -1;
    left: 30px;
  }
`;

export const StepperItemContainer = styled.div`
  height: 90px;
  max-width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:after {
    content: ${(props) =>
      props.number === '1'
        ? "'Verifica documenti'"
        : props.number === '2'
        ? "'Firma Accordo'"
        : props.number === '3'
        ? "'Inizia'"
        : "''"};
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  .radio-buttons {
    .form-group {
      display: flex;
      align-items: center;
      span {
        margin-right: 10px;
      }
      svg {
        width: 20px;
        margin: 0 10px;
      }
    }
  }
  .error-message {
    color: red !important;
  }
`;

export const DocumentUploadContainer = styled.div`
  width: 100%;
  .custom-label {
    background-color: ${variables.primary};
    color: white;
    padding: 12px;
    cursor: pointer;
    width: 260px;
    margin-bottom: 0;
    display: flex;
    align-items: baseline;
    svg {
      fill: ${variables.white};
      margin-right: 8px;
      width: 18px;
    }
  }
  .custom-label-disabled {
    background-color: #9aa0a5;
    color: white;
    padding: 12px;
    cursor: default;
    width: 260px;
    margin-bottom: 0;
    display: flex;
    align-items: baseline;
    svg {
      fill: ${variables.white};
      margin-right: 8px;
      width: 18px;
    }
  }
  .error-message {
    color: red !important;
  }
`;
export const FileUploadContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  .form-group {
    margin-bottom: 0;
  }
`;
export const FileText = styled.p`
  background-color: ${variables.background};
  border: 1px solid ${variables.primary};
  padding: 11px;
  margin: 0;
  width: 150px;
`;
export const LoginContainer = styled.section`
  background-image: url(../images/bg_login.jpg);
  height: 100vh;
  background-size: cover;
  display: flex;
  align-items: center;
`;

export const ContractFrameContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  div {
    width: 100%;
    height: 600px;
    background-color: ${variables.white};
  }
  button {
    margin: 20px 0;
  }
`;
